import "./App.css";
import Main from "./Main/Main";

function App() {
	return (
		<div>
			<h1 className="text-3xl font-sans text-center text-indigo-600 semibold">
				VPN Config Utility
			</h1>
			<div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
				<Main />
			</div>
		</div>
	);
}

export default App;
