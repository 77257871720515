import React from "react";

const UploadFileButton = (props) => {
	return (
		<button
			type="button"
			className={props.classes}
			onClick={props.onClickHandler}
		>
			{props.text}
		</button>
	);
};

export default UploadFileButton;
